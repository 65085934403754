import React from "react";
import Typewriter from "typewriter-effect";
import videobg from "../videos/home.mp4";
import resumeFile from "../documents/resume.pdf";

const Home = ({ classicHeader, darkTheme, handleNavClick }) => {
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />

        {/* ---------------image background------------------ */}
        <div
          className="hero-bg parallax"
          style={{ backgroundImage: 'url("images/generating_melodies_1.jpeg")'}}
        ></div>

        {/* -------------------video background---------------------- */}

        {/* <div className="player hero-bg parallax">
          <video
            src={videobg}
            autoPlay
            muted
            loop
            style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          ></video>
        </div> */}

        <div className="hero-content section d-flex min-vh-100">
          <div className="container my-auto">
            <div className="row">
              <div className="col-12 text-center">
                <h3 class="text-9 fw-500 text-white mb-2 mb-md-3">Tal Rozenzweig</h3>
                <h2 className="text-16 fw-600 text-white mb-2 mb-md-3">
                  <Typewriter
                    options={{
                      strings: [
                        "DevOps Engineer",
                        "Software Developer",
                        "Tech Enthusiast",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h2>
                <p className="text-5 text-light mb-4">
                This website is powered by my private On-Prem K8S cluster that I built in my living room.
                </p>
                <p className="text-9 text-light mb-4">
                How cool is that?
                </p>
                <a
                  href={resumeFile}
                  download
                  className="btn btn-primary rounded-pill"
                >
                  Download CV
                </a>
              </div>
            </div>
          </div>
          <a
            href="#about"
            className="scroll-down-arrow text-white smooth-scroll"
            onClick={(e) => {
              e.preventDefault();
              handleNavClick("about");
            }}
          >
            <span className="animated">
              <i className="fa fa-chevron-down" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
